import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/DefaultLayout/DefaultLayout.js";
import ProductOverview from './ProductOverview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`ProductOverview`}</h1>
    <p>{`// todo: knob stuff`}</p>
    <ProductOverview mdxType="ProductOverview" />

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      